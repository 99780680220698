import { Controller } from 'stimulus'

export default class extends Controller {

  send(e) {
    e.preventDefault()

    let $form = $(e.currentTarget),
        $button = $form.find('button'),
        path = this.data.get('path')

    if ($button.hasClass('btn-loader')) {
      // return
    } else {
      $button.addClass('btn-loader')
    }

    $.ajax({
      type: 'post',
      url: path,
      data: this.getFormData($form),
      success(response) {
        if (response.status) {
          $('.appointment__title').remove()
          $('.appointment__form').remove()
          $('.company-job__done').addClass('company-job_active')
        } else {
          swal('Ошибка отправки формы', '', 'error')
        }
        $button.removeClass('btn-loader')
      },
      error() {
        swal('Ошибка отправки формы', '', 'error')
        $button.removeClass('btn-loader')
      }
    })    
  }

  changeDepartment(e) {
    let departmentId = $(e.currentTarget).val()

    $('.appointment__drop-box_doctors').addClass('appointment__drop-box_hide')
    $('.appointment__drop-box_doctors select').removeAttr('name')

    if (departmentId != '') {
      $(`.appointment__drop-box_doctors[data-department-id=${departmentId}]`).removeClass('appointment__drop-box_hide')
      $(`.appointment__drop-box_doctors[data-department-id=${departmentId}] select`).attr('name', 'doctor_id')
    }
  }

  getFormData($form) {
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function(n, i){
      indexed_array[n['name']] = n['value'];
    });

    return indexed_array;
  }

}