import { Controller } from 'stimulus'

export default class extends Controller {

  change(e) {
    let index = $(e.currentTarget).attr('data-id')
    $('.history__item').removeClass('history__item_active')
    $(`.history__item[data-id=${index}]`).addClass('history__item_active')
  }

}