import { Controller } from 'stimulus'

export default class extends Controller {

  open(e) {
    e.preventDefault()
    let path = this.data.get('path')

    $.ajax({
      type: 'get',
      url: path,
      success(responseHTML) {
        $('html').addClass('popup-opened')
        $('body').append(responseHTML)
      },
      error() {
        swal('Ошибка открытия попапа', '', 'error')
      }
    })
  }

  close(e) {
    e.preventDefault()
    $('html').removeClass('popup-opened')
    $('.modal').remove()
  }

}