import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    $('html').removeClass('popup-opened')
  }

  open(e) {
    $('.header-menu_mobile').addClass('header-menu_mobile-active')
    $('.header_mobile-burger-close').addClass('header_mobile-close-active')
    $('.header_mobile-burger').removeClass('header_mobile_btn-active')
    $('html').addClass('popup-opened')
  }

  close(e) {
    $('.header-menu_mobile').removeClass('header-menu_mobile-active')
    $('.header_mobile-burger-close').removeClass('header_mobile-close-active')
    $('.header_mobile-burger').addClass('header_mobile_btn-active')
    $('html').removeClass('popup-opened')
  }

}