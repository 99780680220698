import { Controller } from 'stimulus'
import Swiper from '../libs/swiper'

export default class extends Controller {

  connect() {
    this.initProductionSwiper()
  }


  initProductionSwiper() {
    new Swiper('.hp-list', {
      // loop: true,
      slidesPerView: 3,
      spaceBetween: 33,
      navigation: {
        prevEl: '.js-button1-prev',
        nextEl: '.js-button1-next',
        size: 13,
      },
      pagination: {
        el: '.js-header-slider-pag',
        type: 'bullets',
        clickable: 'true',
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          pagination: {
            el: '.js-header-slider-pag',
            type: 'bullets',
            clickable: 'true',
          },
        },
        320:{
          slidesPerView: 1,
          pagination: {
            el: '.js-header-slider-pag',
            type: 'bullets',
            clickable: 'true',
          },
        },
        425: {
          slidesPerView: 1,
          pagination: {
            el: '.js-header-slider-pag',
            type: 'bullets',
            clickable: 'true',
          },
        },
        768: {
          slidesPerView: 2,
          noSwiping: true,
          allowSlidePrev: true,
          allowSlideNext: true,
          pagination: {
            el: '.js-header-slider-pag',
            type: 'bullets',
            clickable: 'true',
          },
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1366: {
          slidesPerView: 3,
          pagination: {
            el: '.js-header-slider-pag',
            type: 'bullets',
            clickable: 'true',
          },
        },
      },
    })
  }

}