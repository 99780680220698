import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    let $element = $(this.element)

    $.fn.datepicker.languages['ru-RU'] = {
      format: 'dd.mm.YYYY',
      days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
      daysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
      weekStart: 1,
      startView: 0,
      yearFirst: false,
      yearSuffix: ''
    };

    $element.datepicker('destroy')

    setTimeout(function() {
      $element.datepicker({
        autoPick: true,
        autoHide: true,
        language: 'ru-RU',
        startDate: new Date()
      })
    }, 100)
  }
  
}