import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'city', 'department' ]

  connect(e) {
    let _this = this
    $(this.cityTarget).on('change', function() { _this.filter() })
    $(this.departmentTargets).on('change', function() { _this.filter() })

    _this.filter()
  }

  filter() {
    let $city = $(this.cityTarget),
        $departments = $(this.departmentTargets),
        departmentsChecked = []

    // RESET
    $('.e-card').removeClass('e-card_hide')

    // CITY FILTER
    if ($city.val() == '0') {
      $city.removeClass('drop-box_with-value')
    } else {
      $city.addClass('drop-box_with-value')
      $('.e-card').addClass('e-card_hide')
      $(`.e-card[data-city-id=${$city.val()}]`).removeClass('e-card_hide')
    }

    // DEPARTMENTS FILTER
    $departments.each(function(index, element) {
      if ($(element).prop('checked')) { departmentsChecked.push($(element).val()) }
    })

    if (departmentsChecked.length > 0) {
      $('.e-card:not(.e-card_hide)').each(function(index, element) {
        let elementDepartments = [],
            isFound = false

        $(element).find('.e-card__department').each(function(indexD, elementD) {
          elementDepartments.push($(elementD).attr('data-department-name'))
        })

        for (let i = 0; i < elementDepartments.length; i++) {
          let elementDepartment = elementDepartments[i]
          for (let j = 0; j < departmentsChecked.length; j++) {
            if (elementDepartment == departmentsChecked[j]) {
              isFound = true
              break
            }
          }

          if (isFound) {
            break
          }
        }

        if (!isFound) {
          $(element).addClass('e-card_hide')
        } 
      })
    }

    // UPDATE COUNTER
    $('.entry__counter span').text($('.e-card:not(.e-card_hide)').length)
  }

}