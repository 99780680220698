import { Controller } from 'stimulus'

export default class extends Controller {

  send(e) {
    e.preventDefault()

    let $form = $(e.currentTarget),
        $button = $form.find('button'),
        path = this.data.get('path'),
        form = new FormData()

    if ($button.hasClass('btn-loader')) {
      return
    } else {
      $button.addClass('btn-loader')
    }

    form.append('name',  $form.find('input[name="name"]').val())
    form.append('phone', $form.find('input[name="phone"]').val())
    form.append('email', $form.find('input[name="email"]').val())
    form.append('city',  $form.find('input[name="city"]').val())
    form.append('body',  $form.find('textarea[name="body"]').val())
    form.append('cv',    $form.find('input[name="cv"]').get(0).files[0])

    $.ajax({
      type: 'post',
      url: path,
      data: form,
      contentType: false,
      processData: false,      
      success(response) {
        if (response.status) {
          $('.company-job__form').removeClass('company-job_active')
          $('.company-job__done').addClass('company-job_active')
        } else {
          swal('Ошибка отправки формы', '', 'error')
        }
        $button.removeClass('btn-loader')
      },
      error() {
        swal('Ошибка отправки формы', '', 'error')
        $button.removeClass('btn-loader')
      }
    })    
  }

}