import { Controller } from 'stimulus'

export default class extends Controller {

  send(e) {
    e.preventDefault()

    let $form = $(e.currentTarget),
        $button = $form.find('button'),
        path = this.data.get('path')

    if ($button.hasClass('btn-loader')) {
      return
    } else {
      $button.addClass('btn-loader')
    }

    $.ajax({
      type: 'post',
      url: path,
      data: {
        name:  $form.find('input[name="name"]').val(),
        phone: $form.find('input[name="phone"]').val(),
        email: $form.find('input[name="email"]').val(),
        body:  $form.find('textarea[name="body"]').val()
      },
      success(response) {
        if (response.status) {
          $('.company-job__form').removeClass('company-job_active')
          $('.company-job__done').addClass('company-job_active')
        } else {
          swal('Ошибка отправки формы', '', 'error')
        }
        $button.removeClass('btn-loader')
      },
      error() {
        swal('Ошибка отправки формы', '', 'error')
        $button.removeClass('btn-loader')
      }
    })    
  }

}