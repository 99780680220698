import { Controller } from 'stimulus'

export default class extends Controller {

  send(e) {
    e.preventDefault()

    let $form = $(e.currentTarget),
        $button = $form.find('button'),
        path = this.data.get('path')

    if ($button.hasClass('btn-loader')) {
      return
    } else {
      $button.addClass('btn-loader')
    }

    $.ajax({
      type: 'post',
      url: path,
      data: {
        product_id:  $form.find('input[name="product_id"]').val(),
        first_name:  $form.find('input[name="first_name"]').val(),
        last_name:   $form.find('input[name="last_name"]').val(),
        phone:       $form.find('input[name="phone"]').val(),
        email:       $form.find('input[name="email"]').val(),
        body:        $form.find('textarea[name="body"]').val()
      },
      success(response) {
        if (response.status) {
          $('.products-show__footer').removeClass('products-show__footer_active')
          $('.ps-form__done').addClass('ps-form__done_active')
        } else {
          swal('Ошибка отправки формы', '', 'error')
        }
        $button.removeClass('btn-loader')
      },
      error() {
        swal('Ошибка отправки формы', '', 'error')
        $button.removeClass('btn-loader')
      }
    })    
  }

}