import { Controller } from 'stimulus'

export default class extends Controller {

  connect(e) {
    ymaps.ready(function () {  
      let map = new ymaps.Map("contacts-map", {
        center: [50.025600, 46.872660],
        zoom: 18
      })

      map.controls.remove('geolocationControl')
      map.controls.remove('searchControl')
      map.controls.remove('trafficControl')
      map.controls.remove('typeSelector')
      map.controls.remove('fullscreenControl')
      map.controls.remove('rulerControl')
      map.behaviors.disable(['scrollZoom'])

      let point = new ymaps.Placemark(map.getCenter(), {
        hintContent: 'Quick Medical Solutions'
      }, {
        iconLayout: 'default#image',
        iconImageHref: 'favicon.png',
        iconImageSize: [35, 35],
        iconImageOffset: [0, 0]
      })

      map.geoObjects.add(point)     
    })
  }

}  