import { Controller } from 'stimulus'

export default class extends Controller {

  start() {
    if (this.started) {
      return
    } else {
      this.started = true
      $('.pregnancy-start').html('Определяем...')
    }

    $('.pregnancy-box__circle').addClass('pregnancy-box__circle_anim')

    setTimeout(() => {
      $('.pregnancy-box__women').addClass('animate__animated animate__bounceOut')

      setTimeout(() => {
        const result = this.randomInteger // 1 - boy, 2 - girl
        if (result == 1) {
          $('.pregnancy-box__circle').addClass('pregnancy-box__circle_boy')
          $('.pregnancy-box__female').fadeOut(1000)
        } else {
          $('.pregnancy-box__male').fadeOut(1000)
        }

        setTimeout(() => {
          if (result == 1) {
            $('.pregnancy-title').html('Поздравляем!<br>У вас будет мальчик!')
            $('.pregnancy-box__boy').addClass('animate__animated animate__bounceIn').show()
          } else {
            $('.pregnancy-title').html('Поздравляем!<br>У вас будет девочка!')
            $('.pregnancy-box__girl').addClass('animate__animated animate__bounceIn').show()
          }

          $('.pregnancy-start').hide()
          $('.pregnancy-info').addClass('animate__animated animate__fadeInUp').show()
        }, 1000)
      }, 600)
    }, 3000)
  }

  get randomInteger() {
    const min = 1
    const max = 2
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

}