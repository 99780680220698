// GLOBAL JQUERY
window.$ = window.jQuery = require("jquery")

// DEFAULT
require("@rails/ujs").start()
require("turbolinks").start()
require('@chenfengyuan/datepicker')

// CUSTOM LIBS
require("sweetalert")

// // STIMULUS CONFIG, LOAD ALL CONTROLLERS
require("@stimulus/polyfills")
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("frontend/controllers", true, /.js$/)
application.load(definitionsFromContext(context))
